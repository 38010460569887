<template>
	<div class="distressalertssitrepFormHeader puiformheader">
		<v-row dense>
			<v-col class="col-12 col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
				<pui-field :label="$t('header.distressalertssitrep.id')" :value="getIdValue"></pui-field>
			</v-col>
			<v-col class="col-12 col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
				<pui-field :label="$t('header.distressalertssitrep.numpersonsrisk')" :value="getnumpersonsriskValue"></pui-field>
			</v-col>

			<v-col class="col-12 col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
				<pui-field :label="$t('header.distressalertssitrep.assistancerequired')" :value="getAssistancerequiredValue"></pui-field>
			</v-col>
			<v-col class="col-12 col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
				<pui-field :label="$t('header.distressalertssitrep.coordinatingmrcc')" :value="getCoordinatingmrccValue"></pui-field>
			</v-col>
		</v-row>
		<v-row dense>
			<v-col class="col-12 col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
				<pui-field :label="$t('header.distressalertssitrep.vesselname')" :value="getVesselnameValue"></pui-field>
			</v-col>
			<v-col class="col-12 col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
				<pui-field :label="$t('header.distressalertssitrep.imo')" :value="getImoValue"></pui-field>
			</v-col>
			<v-col class="col-12 col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
				<pui-field :label="$t('header.distressalertssitrep.callsign')" :value="getCallsignValue"></pui-field>
			</v-col>
			<v-col class="col-12 col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
				<pui-field :label="$t('header.distressalertssitrep.flag')" :value="getFlagValue"></pui-field>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';

export default {
	name: 'distressalertssitrep-form-header',
	mixins: [PuiFormHeaderMixin],
	data() {
		return {
			modelName: 'distressalertssitrep'
		};
	},
	computed: {
		getIdValue() {
			return this.model && this.model.id ? this.model.id : '-';
		},
		getVesselnameValue() {
			return this.model && this.model.vesselname ? this.model.vesselname : '-';
		},
		getImoValue() {
			return this.model && this.model.imo ? this.model.imo : '-';
		},
		getFlagValue() {
			return this.model && this.model.flag ? this.model.flag : '-';
		},
		getCallsignValue() {
			return this.model && this.model.callsign ? this.model.callsign : '-';
		},
		getnumpersonsriskValue() {
			return this.model && this.model.numpersonsrisk ? this.model.numpersonsrisk : '-';
		},
		getAssistancerequiredValue() {
			return this.model && this.model.assistancerequired ? this.model.assistancerequired : '-';
		},
		getCoordinatingmrccValue() {
			return this.model && this.model.coordinatingmrcc ? this.model.coordinatingmrcc : '-';
		}
	}
};
</script>
